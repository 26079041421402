import React, {useState} from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";
import classnames from "classnames";
import { AppRoute, AppRouteTitles, MYMAPS_ID } from "../../const";
import {Link} from "react-router-dom";
import {Route} from "../../modules/i18n";


export const Showers: React.FC = () => {
    const {formatMessage} = useIntl();
    const cloth = Route(AppRoute.Clothes);
    const park = Route(AppRoute.Parking);

    const [shower, setShower] = useState(-1);
    let toggleNavs = (e: any, s: number) => {
        e.preventDefault();
        setShower(s);
    };

    React.useEffect(() => {
        if (shower === -1) {
            document.documentElement.scrollTop = 0;
            if (document.scrollingElement !== null)
                document.scrollingElement.scrollTop = 0;
            setShower(1);
        }
    }, [shower]);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.showers.title"})} | SantYaGo10K 2024</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "during.showers.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "during.showers.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/showers.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h1><FormattedMessage id="during.showers.heading"/></h1>
                                    <Row>
                                        <Col lg="6">
                                            <p>
                                                <FormattedMessage
                                                    id="during.showers.subheading"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <span style={{fontWeight: "bold"}}>{chunks}</span>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/showers.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="nav-wrapper">
                                                <Nav
                                                    className="nav-fill flex-column flex-md-row"
                                                    id="tabs-icons-text"
                                                    pills
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <Row>
                                                            <Col>
                                                                <NavLink
                                                                    aria-selected={shower === 1}
                                                                    className={classnames("mb-sm-3 mb-md-0", {
                                                                        active: shower === 1
                                                                    })}
                                                                    onClick={e => toggleNavs(e, 1)} href="#"
                                                                    role="tab"
                                                                >
                                                                    {formatMessage({id: "during.showers.isabel"})}
                                                                </NavLink>
                                                            </Col>
                                                            <Col>
                                                                <NavLink
                                                                    aria-selected={shower === 2}
                                                                    className={classnames("mb-sm-3 mb-md-0", {
                                                                        active: shower === 2
                                                                    })}
                                                                    onClick={e => toggleNavs(e, 2)} href="#"
                                                                    role="tab"
                                                                >
                                                                    {formatMessage({id: "during.showers.lorenzo"})}
                                                                </NavLink>
                                                            </Col>
                                                        </Row>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TabContent activeTab={"shower" + shower.valueOf()}>
                                                <TabPane tabId="shower1">
                                                    <h3>{formatMessage({id: "during.showers.isabel.title"})}</h3>
                                                    <Row>
                                                        <Col lg="6">
                                                            <Card className="shadow shadow-sm mt-1">
                                                                <CardBody>
                                                                    <div className="d-flex px-1">
                                                                        <div className="pl-2">
                                                                            <h5 className="title text-warning">
                                                                                {formatMessage({id: "during.showers.time"})}
                                                                            </h5>
                                                                            <p><span className="fa fa-calendar"/> 22:00
                                                                                - 01:00
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Card className="shadow shadow-sm mt-1">
                                                                <CardBody>
                                                                    <div className="d-flex px-1">
                                                                        <div className="pl-2">
                                                                            <h5 className="title text-warning">
                                                                                {formatMessage({id: "during.showers.address"})}
                                                                            </h5>
                                                                            <p>Rúa Tras Santa Isabel, S/N<br/>15705 -
                                                                                Santiago de
                                                                                Compostela</p>
                                                                            <p><span className="fa fa-phone"/> (+34) 981 52 87 30</p>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3">
                                                        <FormattedMessage
                                                            id="during.showers.isabel.p"
                                                            values={{
                                                                a: () => (
                                                                    <Link to={park}>
                                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Parking)})}
                                                                    </Link>
                                                                ),
                                                            }}
                                                        />
                                                    </p>
                                                    <Row className="align-items-center">
                                                        <Col lg="6">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid shadow"
                                                                src={require('../../assets/img/race/others/shower11.png')}
                                                            />
                                                        </Col>
                                                        <Col lg="6">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid shadow"
                                                                src={require('../../assets/img/race/others/shower12.png')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="shower2">
                                                    <h3>{formatMessage({id: "during.showers.lorenzo.title"})}</h3>
                                                    <Row>
                                                        <Col lg="6">
                                                            <Card className="shadow shadow-sm mt-1">
                                                                <CardBody>
                                                                    <div className="d-flex px-1">
                                                                        <div className="pl-2">
                                                                            <h5 className="title text-warning">
                                                                                {formatMessage({id: "during.showers.time"})}
                                                                            </h5>
                                                                            <p><span className="fa fa-calendar"/> 22:00
                                                                                - 01:00
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Card className="shadow shadow-sm mt-1">
                                                                <CardBody>
                                                                    <div className="d-flex px-1">
                                                                        <div className="pl-2">
                                                                            <h5 className="title text-warning">
                                                                                {formatMessage({id: "during.showers.address"})}
                                                                            </h5>
                                                                            <p>Costa Nova de Abaixo, S/N<br/>15704 -
                                                                                Santiago de
                                                                                Compostela</p>
                                                                            <p><span className="fa fa-phone"/> (+34) 981
                                                                                52 87 32</p>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3">
                                                        <FormattedMessage
                                                            id="during.showers.lorenzo.p"
                                                            values={{
                                                                a: () => (
                                                                    <Link to={cloth}>
                                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Clothes)})}
                                                                    </Link>
                                                                ),
                                                            }}
                                                        />
                                                    </p>
                                                    <Row className="align-items-center">
                                                        <Col lg="6">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid shadow"
                                                                src={require('../../assets/img/race/others/shower21.png')}
                                                            />
                                                        </Col>
                                                        <Col lg="6">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid shadow"
                                                                src={require('../../assets/img/race/others/shower22.png')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col>
                                            <Card className="shadow border-0">
                                                <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0"
                                                          style={{height: 512}}>
                                                    <iframe
                                                        title="map"
                                                        src={`https://www.google.com/maps/d/u/0/embed?mid=${MYMAPS_ID}&z=14`}
                                                        width="100%"
                                                        height="512"
                                                        style={{border: "none"}}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Showers;
