interface PressItem {
    title: string,
    date: number,
    provider: string,
    source: string
}

export const Press: PressItem[] = [
    {
        title: "Jorge Puig, con récord de la prueba, y Tina Fernández ganan la Santyago 10K",
        date: 1713870000,
        provider: "lvdg",
        source: "https://www.lavozdegalicia.es/noticia/santiago/2024/04/28/jorge-puig-record-prueba-tina-fernandez-ganan-santyago-10k/0003_202404S28P45994.htm"
    },
    {
        title: "Último día para la inscripción en la Santyago 10K",
        date: 1713870000,
        provider: "lvdg",
        source: "https://www.lavozdegalicia.es/noticia/santiago/santiago/2024/04/24/ultimo-dia-inscripcion-santyago-10k/0003_202404S24P42993.htm"
    },
    {
        title: "Pedro Nimo prepara el ambiente de la SantYaGo tras una clase de atletismo en El Corte Inglés",
        date: 1712743200,
        provider: "ecg",
        source: "https://www.elcorreogallego.es/deportes/2024/04/10/pedro-nimo-prepara-ambiente-santyago-santiago-compostela-100889533.html"
    },
    {
        title: "Pedro Nimo del Oro, atleta gallego: \"En una maratón competimos contra nosotros mismos\"",
        date: 1712397600,
        provider: "elespanol",
        source: "https://www.elespanol.com/quincemil/articulos/deporte/pedro-nimo-del-oro-atleta-gallego-en-una-maraton-competimos-contra-nosotros-mismos"
    },
    {
        title: "8ª CARRERA NOCTURNA SANTYAGO 10K EN EL CORTE INGLÉS SANTIAGO DE COMPOSTELA",
        date: 1711364400,
        provider: "eci",
        source: "https://www.elcorteingles.es/centroscomerciales/es/eci/centros/centro-comercial-compostela/eventos/santyago10k"
    },
    {
        title: "Aberto o prazo de inscrición para a VIII edición da carreira nocturna SantYaGo 10K o vindeiro 27 de abril",
        date: 1710241200,
        provider: "concello",
        source: "http://santiagodecompostela.gal/hoxe/nova.php?id_nova=23169"
    },
];
