import React from "react";
import {Helmet} from 'react-helmet';

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {useIntl} from "react-intl";
import {HorizontalSponsors} from "../../modules/layout";


export const Photos: React.FC = () => {
    const {formatMessage, locale} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, [locale,]);

    const onClick = () => {
        window.open("https://www.youtube.com/playlist?list=PLikI5D2CZjsq0ziSBd9fD30IDTk-Ezgaz", "_blank");
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "after.photos.title"})} | SantYaGo10K 2024</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 shape-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "after.photos.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "after.photos.subtitle"})}
                                        </p>
                                        <Button
                                          className="btn-icon mb-3 mb-sm-0 ml-1"
                                          color="default"
                                          onClick={onClick}
                                        >
                                                     <span className="btn-inner--icon mr-1">
                                                        <i className="fa fa-youtube-play"/>
                                                     </span>
                                            <span className="btn-inner--text">
                                                        {formatMessage({id: "after.photos.youtube"})}
                                                     </span>
                                        </Button>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/photos.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="row-grid align-elements-center">
                                <Col span={12}>
                                    <Card className="shadow">
                                        <CardBody>
                                            <p className="description">
                                                <li><a
                                                    href="https://photos.app.goo.gl/81RUpeMQGDvWQ1Ko6"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Juanma Fotografía
                                                </a></li>
                                                <li><a
                                                    href="https://www.facebook.com/share/p/aGX94FY1fFgAi9Ug/?mibextid=WC7FNe"
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Fernanda Rivera
                                                </a></li>
                                            </p>

                                            <p className="description">
                                                <iframe
                                                  width="100%"
                                                  height="640"
                                                  src="https://www.youtube.com/embed/Ikyf2sz46lo"
                                                  title="Resumen VIII Edición | SantYaGo10K 2024"
                                                  frameBorder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                  referrerPolicy="strict-origin-when-cross-origin"
                                                  allowFullScreen>
                                                </iframe>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <HorizontalSponsors/>
                </div>
            </main>
        </>
    );
};

export default Photos;
