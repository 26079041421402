import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppRoute, AppRouteTitles, AppLanguage, DefaultLanguage} from '../../../const';
import {useIntl} from 'react-intl';

import {appStrings, Route} from '../../i18n';

import {
    Button,
    UncontrolledCollapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col
} from "reactstrap";

import Headroom from "headroom.js";
import Cookies from 'universal-cookie';


export const Navigation: React.FC = () => {
    const {pathname} = useLocation();
    const {formatMessage, locale, messages} = useIntl();

    function getMatchingRoute(language: string) {
        const route = DefaultLanguage === locale ? pathname : pathname.replace(`/${locale}`, ``);
        const routeKey = Object.keys(messages).find(key => messages[key] === route) as string;

        const matchingRoute = (appStrings as any)[language][routeKey];


        if (matchingRoute === undefined || routeKey === undefined)
            return language === DefaultLanguage ? route : `/${language}` + route;
        else
            return language === DefaultLanguage ? matchingRoute : `/${language}` + matchingRoute;
    }

    function changeLang(lang: string) {
        new Cookies().set('l10n', lang, { path: '/' });
    }

    const [classes, setClasses] = useState<string>("");
    function onExiting () {
        setClasses("collapsing-out");
    }
    function onExited () {
        setClasses("");
    }

    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        headroom.init();
    }, [classes]);

    return (
        <>
            <header className="header-global">
                <Navbar
                    className="navbar-main navbar-transparent navbar-light headroom"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand className="mr-lg-5" to={Route(AppRoute.Home)} tag={Link}>
                            <img
                                className="navbar-white"
                                src={require("../../../assets/img/race/logo.png")}
                                alt="..."
                            />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <UncontrolledCollapse
                            navbar
                            toggler="#navbar_global"
                            className={classes}
                            onExiting={onExiting}
                            onExited={onExited}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to={Route(AppRoute.Home)}>
                                            <img
                                                alt="..."
                                                src={require("../../../assets/img/race/logo.png")}
                                            />
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button className="navbar-toggler" id="navbar_global">
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="fa fa-arrow-left mr-1"/>
                                        <span
                                            className="nav-link-inner--text">{formatMessage({id: AppRouteTitles.get(AppRoute.Before)})}</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            <Media className="d-flex align-items-center"
                                                   to={Route(AppRoute.GetTo)} tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <i className="ni ni-bus-front-12"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-info mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.GetTo)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.before.getto"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Parking)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <i className="ni ni-money-coins"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-info mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Parking)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.before.parking"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Eating)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <i className="ni ni-pin-3"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-info mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Eating)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.before.eating"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Bag)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <i className="ni ni-bag-17"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-info mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Bag)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.before.bag"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="fa fa-dot-circle-o mr-1"/>
                                        <span
                                            className="nav-link-inner--text">{formatMessage({id: AppRouteTitles.get(AppRoute.During)})}</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Track)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="fa fa-map"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-warning mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Track)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.during.track"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Clothes)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="ni ni-shop"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-warning mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Clothes)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.during.clothes"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center"
                                                   to={Route(AppRoute.Drinking)} tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="ni ni-square-pin"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-warning mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Drinking)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.during.drinking"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Showers)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="fa fa-shower"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-warning mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Showers)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.during.showers"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Prizes)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="ni ni-diamond"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-warning mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Prizes)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.during.prizes"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="fa fa-arrow-right mr-1"/>
                                        <span className="nav-link-inner--text">
                                                {formatMessage({id: AppRouteTitles.get(AppRoute.After)})}
                                            </span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            {/* TODO: Photos */}
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Photos)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                    <i className="ni ni-camera-compact"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-success mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Photos)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.after.photos"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Dinner)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                    <i className="ni ni-pin-3"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-success mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Dinner)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.after.dinner"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Sleeping)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                    <i className="fa fa-bed"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-success mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Sleeping)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.after.sleeping"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media className="d-flex align-items-center" to={Route(AppRoute.Visiting)}
                                                   tag={Link}>
                                                <div
                                                    className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                    <i className="fa fa-info"/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h5 className="heading text-success mb-md-1">
                                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Visiting)})}
                                                    </h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {formatMessage({id: "nav.after.visiting"})}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="fa fa-plus"/>
                                        <span
                                            className="nav-link-inner--text">{formatMessage({id: AppRouteTitles.get(AppRoute.Info)})}</span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem to={Route(AppRoute.PastYears)} tag={Link}>
                                            {formatMessage({id: AppRouteTitles.get(AppRoute.PastYears)})}
                                        </DropdownItem>
                                        <DropdownItem to={Route(AppRoute.Regulation)} tag={Link}>
                                            {formatMessage({id: AppRouteTitles.get(AppRoute.Regulation)})}
                                        </DropdownItem>
                                        <DropdownItem to={Route(AppRoute.Terms)} tag={Link}>
                                            {formatMessage({id: AppRouteTitles.get(AppRoute.Terms)})}
                                        </DropdownItem>
                                        <DropdownItem to={Route(AppRoute.Press)} tag={Link}>
                                            {formatMessage({id: AppRouteTitles.get(AppRoute.Press)})}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <NavLink className="nav-link-inner--text" to={Route(AppRoute.Volunteer)} tag={Link}>
                                        <i className="fa fa-user"/>
                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Volunteer)})}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="fa fa-language"/>
                                        <span
                                            className="nav-link-inner--text">{formatMessage({id: "lang"})}</span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(AppLanguage).map(lang => (
                                            <DropdownItem
                                                to={getMatchingRoute((AppLanguage as any)[lang])}
                                                tag={Link}
                                                className={locale === (AppLanguage as any)[lang] ? "active" : ""}
                                                key={lang}
                                                onClick={() => changeLang((AppLanguage as any)[lang])}
                                            >
                                                {formatMessage({id: "lang." + (AppLanguage as any)[lang]})}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem className="d-lg-block ml-lg-4">
                                    {/*}<Button
                                      className="btn-neutral btn-icon text-orange"
                                      color="default"
                                      to={Route(AppRoute.Join)}
                                      tag={Link}
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-user-run mr-2"/>
                                        </span>
                                        <span className="nav-link-inner--text ml-1">
                                            {formatMessage({id: AppRouteTitles.get(AppRoute.Join)})}
                                        </span>
                                    </Button>{*/}
                                    {/* TODO: Results */}
                                    <Button
                                        className="btn-neutral btn-icon text-orange"
                                        color="default"
                                        to={Route(AppRoute.Results)}
                                        tag={Link}
                                    >
                                            <span className="btn-inner--icon">
                                                <i className="ni ni-bullet-list-67 mr-2"/>
                                            </span>
                                        <span className="nav-link-inner--text ml-1">
                                                {formatMessage({id: AppRouteTitles.get(AppRoute.Results)})}
                                            </span>
                                    </Button>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
};

export default Navigation;
